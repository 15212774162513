import React, {
  forwardRef,
  useImperativeHandle,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
  KeyboardSensor,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Button,
  Card,
  Checkbox,
  Classes,
  Dialog,
  InputGroup,
  Icon,
  Intent,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import Fuse from 'fuse.js';

import { ErrorToaster } from '../toast/Toaster';
import { STRINGS } from '../../strings';
import { TableColumnDef } from '../tables/Table';

import './ColumnChooser.scss';

type Column = TableColumnDef & {
  isSelected?: boolean;
};

type Facet = {
  id: string;
  label: string;
  isSelected: boolean;
};

type ColumnChooserProps = {
  columns: Array<Column> | Array<Facet> | Array<any>;
  selectedIds: Array<string>;
  handleColumnsUpdate: (selectedIds: string[]) => void;
  enableDragging?: boolean;
  title?: string;
  /** left-hand-side title */
  lhsTitle?: string;
  /** right-hand-side title */
  rhsTitle?: string;
  /** used for facet choosers */
  isFacet?: boolean;
};

type SortableItemProps = {
  id: string;
  column: Column | any;
  handleCheckboxChange: (
    event: React.ChangeEvent<HTMLElement>,
    id: string,
    listSetter: React.Dispatch<React.SetStateAction<any[]>>,
    isShiftDown: boolean,
    lastChecked: string | null,
    setLastChecked: React.Dispatch<React.SetStateAction<string | null>>
  ) => void;
  setSelectedColumns: React.Dispatch<React.SetStateAction<any[]>>;
  isFocused: boolean;
  enableDragging: boolean;
  isShiftDown: boolean;
  lastChecked: string | null;
  setLastChecked: React.Dispatch<React.SetStateAction<string | null>>;
  isFacet?: boolean;
};

type ClearInputProps = {
  type: 'selected' | 'available';
  selectedSearchQuery?: string;
  setSelectedSearchQuery?: (value: string) => void;
  availableSearchQuery?: string;
  setAvailableSearchQuery?: (value: string) => void;
};

/** enables fuzzy search via `fuse.js` */
const getFuseOptions = (isFacet: boolean) => ({
  /** what `fuse` will use for the baseline as a string */
  keys: isFacet ? ['label'] : ['Header'],
  /** amount of mistake allowed, higher number equates to more mistakes */
  threshold: 0.3,
});

/** Renders a list that allows for drag-and-drop if enabled. */
const SortableItem = ({
  id,
  column,
  handleCheckboxChange,
  setSelectedColumns,
  isFocused,
  enableDragging,
  isShiftDown,
  lastChecked,
  setLastChecked,
  isFacet,
}: SortableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    disabled: column.id === "more",
  });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
    borderBottom: '1px solid #e1e1e1',
    backgroundColor: isDragging ? '#fafafa' : 'white',
    outline: isDragging
      ? '2px dashed #0077cc'
      : isFocused
      ? '2px dashed #0077cc'
      : 'none',
  };

  /** if not using facet/column data, will need to extend to cover custom case */
  const labelText = isFacet ? column.label : column.Header;

  return (
    <li
      className="SortableItem-list sortable-item column-item"
      data-testid="SelectedColumns-list-element"
      ref={setNodeRef}
      style={style}
      onMouseDown={(e) => e.preventDefault()}
    >
      <Checkbox
        checked={column.isSelected || false}
        onClick={(e) => e.stopPropagation()}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleCheckboxChange(
            e,
            column.id,
            setSelectedColumns,
            isShiftDown,
            lastChecked,
            setLastChecked
          )
        }
      >
        {labelText}
      </Checkbox>
      {enableDragging && column.id !== "more" ? (
        <Button
          icon={<Icon icon={IconNames.DRAG_HANDLE_VERTICAL} />}
          minimal
          small
          style={{ marginLeft: 'auto', cursor: 'grab' }}
          {...listeners}
          {...attributes}
        />
      ) : null}
    </li>
  );
};

/**
 * ColumnChooser component for managing and organizing columns.
 *
 * This component allows users to select, deselect, and reorder columns.
 * It provides a dialog interface with two lists: available columns on the left
 * and selected columns on the right. Users can search through the columns,
 * add or remove columns from the selected list, and reorder selected columns
 * using drag-and-drop functionality.
 */
export const ColumnChooser = forwardRef(
  (
    {
      columns,
      selectedIds,
      handleColumnsUpdate,
      enableDragging = true,
      title = STRINGS.COLUMN_CHOOSER.title,
      lhsTitle = STRINGS.COLUMN_CHOOSER.availableColumns,
      rhsTitle = STRINGS.COLUMN_CHOOSER.selectedColumns,
      isFacet = false,
    }: ColumnChooserProps,
    ref
  ) => {
    const [availableColumns, setAvailableColumns] = useState(
      columns.filter((c) => !selectedIds.includes(c.id))
    );
    const [selectedColumns, setSelectedColumns] = useState(
      columns.filter((c) => selectedIds.includes(c.id))
    );
    const [focusedColumn] = useState<{
      list: 'available' | 'selected';
      index: number;
    } | null>(null);

    const [availableSearchQuery, setAvailableSearchQuery] = useState('');
    const [selectedSearchQuery, setSelectedSearchQuery] = useState('');
    const [isShiftDown, setIsShiftDown] = useState(false);
    const [lastChecked, setLastChecked] = useState<string | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const scrollPositionRef = useRef(0);
    const availableRef = useRef<HTMLDivElement | null>(null);
    const selectedRef = useRef<HTMLDivElement | null>(null);
    const listEl = useRef<HTMLUListElement | null>(null);
    const selectedColumnsListRef = useRef<HTMLUListElement | null>(null);

    useEffect(() => {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Shift') {
          // saving scroll position to ensure change events don't jank up/down
          scrollPositionRef.current =
            selectedColumnsListRef?.current?.scrollTop ?? 0;
          if (selectedColumnsListRef.current) {
            selectedColumnsListRef.current.scrollTop =
              scrollPositionRef.current;
          }
          setIsShiftDown(true);
        }
      };

      const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === 'Shift') {
          setIsShiftDown(false);
          // saving scroll position to ensure change events don't jank up/down
          if (selectedColumnsListRef.current) {
            selectedColumnsListRef.current.scrollTop =
              scrollPositionRef.current;
          }
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('keyup', handleKeyUp);
      };
    }, []);

    useEffect(() => {
      const orderedSelectedColumns = selectedIds
        .map((id) => {
          const column = columns.find((column) => column.id === id);
          return {
            ...column,
            isSelected: column?.isSelected || false,
          };
        })
        .filter((column): column is Column => column?.id);
      setSelectedColumns(orderedSelectedColumns);
      setAvailableColumns(
        columns.filter((column) => !selectedIds.includes(column.id))
      );
    }, [selectedIds, columns]);

    useImperativeHandle(ref, () => ({
      handleOpen() {
        setSelectedColumns(columns.filter((c) => selectedIds.includes(c.id)));
        setAvailableColumns(columns.filter((c) => !selectedIds.includes(c.id)));
        setIsOpen(true);
      },
    }));

    // used to always ensure that the scroll position of the <ul> does not
    // change
    useEffect(() => {
      if (selectedColumnsListRef.current) {
        selectedColumnsListRef.current.scrollTop = scrollPositionRef.current;
      }
    }, [selectedColumns, scrollPositionRef.current]);


    /** forces the "actions" table column always be last in the list */
    const ensureMoreColumnLast = useCallback((columnIds: string[]) => {
      const moreIndex = columnIds.indexOf('more');
      if (moreIndex !== -1) {
        const newIds = [...columnIds];
        newIds.splice(moreIndex, 1);
        newIds.push('more');
        return newIds;
      }
      return columnIds;
    }, []);

    /** enables fuzzy search on the left-hand-side input */
    const fuseAvailable = new Fuse(availableColumns, getFuseOptions(isFacet));
    /** enables fuzzy search on the right-hand-side input */
    const fuseSelected = new Fuse(selectedColumns, getFuseOptions(isFacet));

    const filteredAvailableColumns = availableSearchQuery
      ? fuseAvailable.search(availableSearchQuery).map(({ item }) => item)
      : availableColumns;

    const filteredSelectedColumns = selectedSearchQuery
      ? fuseSelected.search(selectedSearchQuery).map(({ item }) => item)
      : selectedColumns;

    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    const handleAdd = () => {
      const selected = availableColumns.filter((c) => c.isSelected);
      const newSelected = selected.map((c) => ({ ...c, isSelected: false }));
      setSelectedColumns([...selectedColumns, ...newSelected]);
      setAvailableColumns(availableColumns.filter((c) => !c.isSelected));
      // clear both inputs
      setAvailableSearchQuery('');
      setSelectedSearchQuery('');
    };

    const handleRemove = () => {
      const unselected = selectedColumns.filter((c) => c.isSelected);
      const newAvailable = unselected.map((c) => ({ ...c, isSelected: false }));
      setAvailableColumns([...availableColumns, ...newAvailable]);
      setSelectedColumns(selectedColumns.filter((c) => !c.isSelected));
      // clear both inputs
      setAvailableSearchQuery('');
      setSelectedSearchQuery('');
    };

    const handleCheckboxChange = (
      event: React.ChangeEvent<HTMLElement>,
      id: string,
      listSetter: React.Dispatch<React.SetStateAction<any[]>>,
      isShiftDown: boolean,
      lastChecked: string | null,
      setLastChecked: React.Dispatch<React.SetStateAction<string | null>>
    ) => {
      event.stopPropagation();

      // saving scroll position to ensure change events don't jank up/down
      if (selectedColumnsListRef.current) {
        scrollPositionRef.current = selectedColumnsListRef.current.scrollTop;
      }

      const currentFilteredList =
        listSetter === setAvailableColumns
          ? filteredAvailableColumns
          : filteredSelectedColumns;

      if (isShiftDown && lastChecked) {
        const lastCheckedIndex = currentFilteredList.findIndex(
          (item) => item.id === lastChecked
        );
        const currentCheckedIndex = currentFilteredList.findIndex(
          (item) => item.id === id
        );
        const start = Math.min(lastCheckedIndex, currentCheckedIndex);
        const end = Math.max(lastCheckedIndex, currentCheckedIndex);

        const shouldCheck = !currentFilteredList.find((item) => item.id === id)
          ?.isSelected;
        const updatedColumns = currentFilteredList.map((item, index) => {
          if (index >= start && index <= end) {
            return { ...item, isSelected: shouldCheck };
          }
          return item;
        });

        listSetter((prev) =>
          prev.map(
            (item) => updatedColumns.find((u) => u.id === item.id) || item
          )
        );
      } else {
        listSetter((prev) =>
          prev.map((item) =>
            item.id === id ? { ...item, isSelected: !item.isSelected } : item
          )
        );
      }

      setLastChecked(id);
    };

    const handleSave = () => {
      if (isFacet === true && selectedColumns.length === 0) {
        ErrorToaster({
          message: STRINGS.COLUMN_CHOOSER.errorMessage,
        });

        return;
      }
      const columnIds = selectedColumns.map((c) => c.id);
      const orderedColumnIds = ensureMoreColumnLast(columnIds);
      handleColumnsUpdate(orderedColumnIds);
      setIsOpen(false);
    };

    const handleDragEnd = (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        setSelectedColumns((items) => {
          // separate sortable and non-sortable columns
          const sortableColumns = items.filter((item) => item.id !== 'more');
          const nonSortableColumns = items.filter((item) => item.id === 'more');

          const sortableIDs = sortableColumns.map((item) => item.id);

          // find indices within sortable items
          const oldIndex = sortableIDs.findIndex((id) => id === active.id);
          let newIndex = sortableIDs.findIndex((id) => id === over?.id);

          // newIndex must be within bounds
          if (newIndex === -1) {
            newIndex = sortableIDs.length - 1;
          }

          const newSortableItems = arrayMove(sortableColumns, oldIndex, newIndex);

          const newItems = [...newSortableItems, ...nonSortableColumns];
          return newItems;
        });
      }

      // saving scroll position to ensure drag-and-drop doesn't jank up/down
      if (selectedColumnsListRef.current) {
        scrollPositionRef.current = selectedColumnsListRef.current.scrollTop;
      }
    };

    /** the amount of checked inputs for the RHS */
    const availableSelectedCount = availableColumns.filter(
      (col) => !!col.isSelected
    ).length;

    /** the amount of checked inputs for the LHS */
    const selectedSelectedCount = selectedColumns.filter(
      (col) => !!col.isSelected
    ).length;

    /** appears on the left-hand-side of the column chooser */
    const availableColumnsText =
      availableSelectedCount === 0
        ? `${filteredAvailableColumns.length} items available`
        : `${availableSelectedCount} selected`;

    /** appears on the right-hand-side of the column chooser */
    const selectedColumnsText =
      selectedSelectedCount === 0
        ? `${filteredSelectedColumns.length} items selected`
        : `${selectedSelectedCount} selected`;

    const handleCancel = () => {
      setSelectedColumns(columns.filter((c) => selectedIds.includes(c.id)));
      setAvailableColumns(columns.filter((c) => !selectedIds.includes(c.id)));
      setIsOpen(false);
    };

    /** When enabled, provided ability to drag items for re-ordering on the
     * right-hand-side */
    const DraggableList = ({ isFacet }: { isFacet?: boolean }) => {
      const sortableColumns = filteredSelectedColumns.filter(
        (column) => column.id !== 'more'
      );
      const nonSortableColumns = filteredSelectedColumns.filter(
        (column) => column.id === 'more'
      );

      return (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={sortableColumns.map((column: Column | Facet | any) => column.id)}
            strategy={verticalListSortingStrategy}
          >
            <ul
              className="ColumnChooser-list column-container"
              ref={selectedColumnsListRef}
            >
              {sortableColumns.map((column: Column | Facet | any, index: number) => (
                  <SortableItem
                    key={column.id}
                    id={column.id}
                    column={column}
                    handleCheckboxChange={handleCheckboxChange}
                    setSelectedColumns={setSelectedColumns}
                    isFocused={
                      focusedColumn?.list === 'selected' &&
                      focusedColumn.index === index
                    }
                    enableDragging={enableDragging}
                    isShiftDown={isShiftDown}
                    lastChecked={lastChecked}
                    setLastChecked={setLastChecked}
                    isFacet={isFacet}
                  />
                )
              )}
              {/* NOTE: render non-sortable items at the end */}
              {nonSortableColumns.map((column) => (
                <SortableItem
                  key={column.id}
                  id={column.id}
                  column={column}
                  handleCheckboxChange={handleCheckboxChange}
                  setSelectedColumns={setSelectedColumns}
                  isFocused={false}
                  enableDragging={false}
                  isShiftDown={isShiftDown}
                  lastChecked={lastChecked}
                  setLastChecked={setLastChecked}
                  isFacet={isFacet}
                />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      );
    };

    /** Clear text control for the `<InputGroup />` */
    const ClearInput = ({
      type,
      selectedSearchQuery,
      setSelectedSearchQuery,
      availableSearchQuery,
      setAvailableSearchQuery,
    }: ClearInputProps) => {
      const clearType =
        type === 'selected'
          ? {
              query: selectedSearchQuery,
              clear: setSelectedSearchQuery,
            }
          : {
              query: availableSearchQuery,
              clear: setAvailableSearchQuery,
            };

      return clearType.query ? (
        <Icon
          className="ColumnChooser-Input-clear"
          data-testid="ColumnChooser-Input-clear"
          icon="cross"
          intent="none"
          onClick={() => clearType.clear && clearType.clear('')}
        />
      ) : null;
    };

    /** used to determine card size based on columns/facets length */
    const columnSize = () => {
      if (columns.length >= 24) {
        return 'large';
      }
      if (columns.length < 24 && columns.length >= 16) {
        return 'medium';
      }
      if (columns.length < 16) {
        return 'small';
      }
      return '';
    };

    return (
      <>
        <Dialog
          className={`ColumnChooser-container Container-${columnSize()} ${
            Classes.DIALOG
          }`}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title={title}
        >
          <Card className="ColumnChooser-card">
            <section className="ColumnChooser-column-left" ref={availableRef}>
              <div className="ColumnChooser-search-container">
                <p className="ColumnChooser-search-title">{lhsTitle}</p>
                <p
                  className="ColumnChooser-search-amount"
                  data-testid="AvailableColumns-search-amount"
                >
                  {availableColumnsText}
                </p>
              </div>
              <InputGroup
                leftIcon="search"
                placeholder="Search..."
                value={availableSearchQuery}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAvailableSearchQuery(e.target.value)
                }
                rightElement={
                  <ClearInput
                    type="available"
                    availableSearchQuery={availableSearchQuery}
                    setAvailableSearchQuery={setAvailableSearchQuery}
                  />
                }
              />
              <ul
                id="availableColumns"
                className="ColumnChooser-list column-container"
                ref={listEl}
              >
                {filteredAvailableColumns.map((column, index) => (
                  <li
                    key={column.id}
                    className={`ColumnChooser-list-items column-item ${
                      focusedColumn?.list === 'available' &&
                      focusedColumn.index === index
                        ? 'focused'
                        : ''
                    }`}
                    data-testid="AvailableColumns-list-element"
                    onChange={(e: React.ChangeEvent<HTMLLIElement>) =>
                      handleCheckboxChange(
                        e,
                        column.id,
                        setAvailableColumns,
                        isShiftDown,
                        lastChecked,
                        setLastChecked
                      )
                    }
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    <Checkbox
                      checked={column.isSelected || false}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {isFacet ? column.label : column.Header}
                    </Checkbox>
                  </li>
                ))}
              </ul>
            </section>
            <section className="ColumnChooser-column-middle">
              <Button
                data-testid="ColumnChooser-controls-add"
                icon="arrow-right"
                onClick={handleAdd}
                className="column-chooser--middle-button"
                outlined
              >
                {STRINGS.COLUMN_CHOOSER.add}
              </Button>
              <Button
                data-testid="ColumnChooser-controls-remove"
                icon="arrow-left"
                onClick={handleRemove}
                className="column-chooser--middle-button"
                outlined
              >
                {STRINGS.COLUMN_CHOOSER.remove}
              </Button>
            </section>
            <section className="ColumnChooser-column-right" ref={selectedRef}>
              <div className="ColumnChooser-search-container">
                <p className="ColumnChooser-search-title">{rhsTitle}</p>
                <p
                  className="ColumnChooser-search-amount"
                  data-testid="SelectedColumns-search-amount"
                >
                  {selectedColumnsText}
                </p>
              </div>
              <InputGroup
                leftIcon="search"
                placeholder="Search..."
                value={selectedSearchQuery}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedSearchQuery(e.target.value)
                }
                rightElement={
                  <ClearInput
                    type="selected"
                    selectedSearchQuery={selectedSearchQuery}
                    setSelectedSearchQuery={setSelectedSearchQuery}
                  />
                }
              />
              {enableDragging ? (
                <DraggableList isFacet={isFacet} />
              ) : (
                <ul
                  className="ColumnChooser-list column-container"
                  id="selectedColumns"
                >
                  {filteredSelectedColumns.map((column, index) => (
                    <SortableItem
                      key={column.id}
                      id={column.id}
                      column={column}
                      handleCheckboxChange={handleCheckboxChange}
                      setSelectedColumns={setSelectedColumns}
                      isFocused={
                        focusedColumn?.list === 'selected' &&
                        focusedColumn.index === index
                      }
                      enableDragging={enableDragging}
                      isShiftDown={isShiftDown}
                      lastChecked={lastChecked}
                      setLastChecked={setLastChecked}
                      isFacet={isFacet}
                    />
                  ))}
                </ul>
              )}
            </section>
          </Card>
          <div className={`ColumnChooser-footer ${Classes.DIALOG_FOOTER}`}>
            <div className="ColumnChooser-Submission-controls">
              <Button
                data-testid="ColumnChooser-controls-cancel"
                onClick={handleCancel}
                outlined
              >
                {STRINGS.COLUMN_CHOOSER.cancel}
              </Button>
              <Button
                data-testid="ColumnChooser-controls-apply"
                intent={Intent.PRIMARY}
                onClick={handleSave}
                outlined={false}
              >
                {STRINGS.COLUMN_CHOOSER.apply}
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
);
